
import { Vue, Component } from "vue-property-decorator";
import { EmpresaService } from "@/core/services/compras/EmpresaService";

@Component
export default class CfgBoleto extends Vue {

  filter: {
    empresasIds:number,
    dataEmissaoInicial:string,
    dataEmissaoFinal:string,
    dataAssinaturaInicial:string,
    dataAssinaturaFinal:string,
    dataEnvioAssinaturaInicial:string,
    dataEnvioAssinaturaFinal:string,
  } = {
    empresasIds: 0,
    dataEmissaoInicial: "",
    dataEmissaoFinal: "",
    dataAssinaturaInicial: "",
    dataAssinaturaFinal: "",
    dataEnvioAssinaturaInicial: "",
    dataEnvioAssinaturaFinal: "",
  }; 

  valid = true;
  empresasIds: any = [];

  mounted() { 

    new EmpresaService().Listar(-1, -1, ['nomeFantasia'],[],'',[], '', '' , 'Id, nomeFantasia', '').then(
      res => {
        this.empresasIds = res.data.items;
      }
    );
  } 

  Visualizar() {
    if((!this.filter.dataEmissaoInicial || !this.filter.dataEmissaoFinal) && (!this.filter.dataAssinaturaInicial || !this.filter.dataAssinaturaFinal) && (!this.filter.dataEnvioAssinaturaInicial || !this.filter.dataEnvioAssinaturaFinal)){
        this.$swal("Aviso", "Obrigatório preencher início e fim de pelo menos um dos campos.", "warning");
        return true;
    }
    let routeLink = this.$router.resolve({ 
      name: "RelContratosDigitaisTotalEnviado",
      query: {
      empresasIds: this.filter.empresasIds ? this.filter.empresasIds.toString() : "",
      dataEmissaoInicial: this.filter.dataEmissaoInicial ? this.filter.dataEmissaoInicial.toString() : "",
      dataEmissaoFinal: this.filter.dataEmissaoFinal ? this.filter.dataEmissaoFinal.toString() : "",
      dataAssinaturaInicial: this.filter.dataAssinaturaInicial ? this.filter.dataAssinaturaInicial.toString() : "",
      dataAssinaturaFinal: this.filter.dataAssinaturaFinal ? this.filter.dataAssinaturaFinal.toString() : "",
      dataEnvioAssinaturaInicial: this.filter.dataEnvioAssinaturaInicial ? this.filter.dataEnvioAssinaturaInicial.toString() : "",
      dataEnvioAssinaturaFinal: this.filter.dataEnvioAssinaturaFinal ? this.filter.dataEnvioAssinaturaFinal.toString() : "",
      },
    });
    window.open(routeLink.href, "_blank");
  }
}
